<template>
  <div>
    <details open>
      <summary>我是标题</summary>
    </details>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>