import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/Home/Home.vue";
import Index from "../views/Index";
import NotFound from "../views/404.vue";

Vue.use(VueRouter);


const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "/platform",
        name: "Platform",
        component: () => import("../views/Platform/platform.vue"),
      },
      {
        path: "/chrome",
        name: "Chrome",
        component: () => import("../components/AboutUs/InPlug/chrome.vue"),
      },
      {
        path: "/household",
        name: "Household",
        component: () => import("../components/Scene/household.vue"),
      },
      {
        path: "/mores",
        name: "More",
        component: () => import("../components/Scene/more.vue"),
      },
      {
        path: "/work",
        name: "Work",
        component: () => import("../components/Scene/work.vue"),
      },
      {
        path: "/join",
        name: "Join",
        component: () => import("../views/Join/index.vue"),
      },
      {
        path: "/serve",
        name: "Serve",
        component: () => import("../views/Serve/index.vue"),
        children: [
          {
            path: "/",
            name: "sale",
            component: () => import("../components/ServeChild/sale/index.vue"),
          },
          {
            path: "issue",
            name: "issue",
            component: () => import("../components/ServeChild/issue/index.vue"),
          },
          {
            path: "issueAbout",
            name: "issueAbout",
            component: () =>
              import("../components/ServeChild/issue/issueAbout.vue"),
          },

          {
            path: "specification",
            name: "specification",
            component: () =>
              import("../components/ServeChild/specification/index.vue"),
          },
          {
            path: "instructions",
            name: "instructions",
            component: () =>
              import("../components/ServeChild/instructions/index.vue"),
          },
        ],
      },
      {
        path: "Y02B",
        name: "Y02B",
        component: () => import("../components/Shop/Y02B.vue"),
      },
      {
        path: "Y02L",
        name: "Y02L",
        component: () => import("../components/Shop/Y02L.vue"),
      },
      {
        path: "Y03B",
        name: "Y03B",
        component: () => import("../components/Shop/Y03B.vue"),
      },
      {
        path: "Y03L",
        name: "Y03L",
        component: () => import("../components/Shop/Y03L.vue"),
      },
      {
        path: "Y05",
        name: "Y05",
        component: () => import("../components/Shop/Y05.vue"),
      },
      {
        path: "/synopsis",
        name: "Synopsis",
        component: () => import("../components/AboutUs/Synopsis/index.vue"),
      },
      {
        path: "/inPlug",
        name: "InPlug",
        component: () => import("../components/AboutUs/InPlug/index.vue"),
      },
      
      {
        path: "/new",
        name: "New",
        component: () => import("../components/AboutUs/New/index.vue"),
        children: [],
      },
      {
        path: "/particular",
        name: "Particular",
        component: () => import("../components/AboutUs/Particular/index.vue"),
      },
      {
        path: "newAbout",
        name: "newAbout",
        component: () => import("../components/AboutUs/New/newAbout.vue"),
      },
      {
        path: "/recruit",
        name: "Recruit",
        component: () => import("../components/AboutUs/Recruit/index.vue"),
      },
      {
        path: "/relation",
        name: "Relation",
        component: () => import("../components/AboutUs/Relation/index.vue"),
      },
   
    ],
    
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/helloWorld",
    name: "HelloWorld",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/HelloWorld.vue"),
  },

  // 404 page must be placed at the end !!!
  { path: "*", component: NotFound, hidden: true },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // 如果之前有保存滚动位置，则恢复到之前保存的位置
      return savedPosition;
    } else {
      // 否则，默认滚动到页面顶部
      return { x: 0, y: 0 };
    }
  },
});
router.beforeEach((to, from, next) => {
  if (true) next();
  else next();
});

export default router;
