import request from "../../utils/request";
export function getBanner() {
  return request({
    url: "/owsAppRoundPic/findOwsRoundPic",
    method: "post",
  });
}
// 行业新闻
export function getNew() {
  return request({
    url: "/owsArticle/findArticleNewest",
    method: "get",
  });
}
// 企业新闻
export function getQYNews() {
  return request({
    url: "/owsArticle/findArticleNewest2",
    method: "get",
  });
}
// 新闻动态
export function getNewsInfo(params) {
  return request({
    url: "/owsArticle/findArticleAll",
    method: "post",
    params,
  });
}
//  智能指纹锁
export function getFingerprint() {
  return request({
    url: "/owsProductInfo/findAll",
    method: "post",
  });
}
export function plugIn() {
  return request({
    url: "",
    method: "post",
  });
}
//  新闻列表
export function getNewAbout(articleId) {
  return request({
    url: "owsArticle/findArticleById",
    method: "post",
    params: {
      articleId,
    },
  });
}
// 下载链接
export function getDownload(type) {
  return request({
    url: "fileDownload/getLatestByType",
    method: "post",
    params:{
      type
    }
  });
}
