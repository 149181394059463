<template>
  <div style="min-height: 100%;">
    <div style="height: 3.75rem;">
      <NavList></NavList>
    </div>
    <router-view style="min-height: 64.7vh;"></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import NavList from '../../components/NacList'
import Footer from '../../components/Footer'
export default {
    name:'Index',
    components:{
        NavList,
        Footer
    }
}
</script>
<style lang="scss" scoped>
</style>